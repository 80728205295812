<template>
  <div class="landing-page">
    <dy-table
      height="700px"
      :dataList="dataList"
      :rowList="rowList"
      :showPagination="true"
      :page="page"
      :pageSize="pageSize"
      :total="total"
      @pageChange="pageChange"
    >
      <template #filter>
        <el-button @click="refreshData">刷新数据</el-button>
        <c-input  clearable style='width:300px;margin-left:10px' placeholder='备注' v-model='search'>
          <el-button icon='el-icon-search' slot='append' @click='handleSearch'></el-button>
        </c-input>
      </template>
      <template #createdTime="{ row }">
        <span>{{ customFormaTime(row.createdTime) }}</span>
      </template>
      <template #content="{ row }">
        <span>《{{ customContent(row.content) }}》</span>
      </template>
      <template #isDefault="{row}">
          <el-tag :type="row.isDefault === 0 ? 'info':'success' ">
            {{row.isDefault === 0 ? '未指定' :'指定'}}
          </el-tag>
      </template>
      <template #operation="{ row }">
        <c-button @click='(cb)=>{handleAddRemark(cb,row)}' type='primary' size='small' class='button-small'>备注</c-button>
        <c-button
          :autoConfirm="true"
          @confirm="
            (cb) => {
              deleteItem(cb, row);
            }
          "
          type='danger'
          size='small'
          class="button-small"
          >删除</c-button
        >
      </template>
    </dy-table>
  </div>
</template>

<script>
import { getLandingPageList, delLandingPage, setDefaultLandingPage, addLandingPageRemark } from '@/api/baidu'
import { formatTime } from '@/assets/js/utils.js'
export default {
  data () {
    return {
      dataList: [],
      rowList: [
        {
          label: 'ID',
          prop: 'id'
        },

        // {
        //   label: '是否指定',
        //   prop: 'isDefault',
        //   slot: true
        // },
        {
          label: '站点名称',
          prop: 'content',
          slot: true
        },
        {
          label: '备注',
          prop: 'remark'
        },
        {
          label: '创建时间',
          prop: 'createdTime',
          slot: true
        },
        {
          label: '操作',
          action: 'operation'
        }
      ],
      page: 1,
      pageSize: 15,
      total: 0,
      search: ''
    }
  },
  mounted () {
    this.initData()
  },
  watch: {},
  methods: {
    initData (page) {
      if (page) {
        this.page = page
      }
      getLandingPageList({ page: this.page, pageSize: this.pageSize, remark: this.search }).then(
        (res) => {
          this.dataList = res.list
          this.total = res.total
        }
      )
    },
    customFormaTime (val) {
      return formatTime(val)
    },
    customContent (content) {
      const config = JSON.parse(
        JSON.parse(JSON.parse(content).params[0])[0].pageConfig
      )
      return config.siteName
    },
    pageChange (page) {
      this.page = page
      this.initData()
    },
    handleAppoint (cb, row) {
      setDefaultLandingPage(row.id).then(() => {
        this.$message.success('指定成功!')
        this.initData()
      }).finally(() => {
        cb()
      })
    },
    handleAddRemark (cb, row) {
      this.$prompt('请输入备注! (不能超过100个字)', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputValue: `${row.remark ? row.remark : ''}`
      }).then(({ value }) => {
        if (value.length > 100) {
          return this.$message.error('备注不能超过100个字符!')
        }
        addLandingPageRemark(row.id, value).then(() => {
          this.$message.success('修改成功!')
          this.initData()
        })
      }).catch(() => {}).finally(() => {
        cb()
      })
    },
    handleSearch(){
     this.page = 1
     this.initData()
    },
    refreshData(){
       this.initData()
    },
    deleteItem (cb, row) {
      delLandingPage(row.id)
        .then(() => {
          this.$message.success('删除成功!')
          this.initData()
        })
        .finally(() => {
          cb()
        })
    }
  },
  components: {}
}
</script>
<style lang='scss' scoped>
.landing-page {
}
</style>
