var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"landing-page"},[_c('dy-table',{attrs:{"height":"700px","dataList":_vm.dataList,"rowList":_vm.rowList,"showPagination":true,"page":_vm.page,"pageSize":_vm.pageSize,"total":_vm.total},on:{"pageChange":_vm.pageChange},scopedSlots:_vm._u([{key:"filter",fn:function(){return [_c('el-button',{on:{"click":_vm.refreshData}},[_vm._v("刷新数据")]),_c('c-input',{staticStyle:{"width":"300px","margin-left":"10px"},attrs:{"clearable":"","placeholder":"备注"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}},[_c('el-button',{attrs:{"slot":"append","icon":"el-icon-search"},on:{"click":_vm.handleSearch},slot:"append"})],1)]},proxy:true},{key:"createdTime",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.customFormaTime(row.createdTime)))])]}},{key:"content",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v("《"+_vm._s(_vm.customContent(row.content))+"》")])]}},{key:"isDefault",fn:function(ref){
var row = ref.row;
return [_c('el-tag',{attrs:{"type":row.isDefault === 0 ? 'info':'success'}},[_vm._v(" "+_vm._s(row.isDefault === 0 ? '未指定' :'指定')+" ")])]}},{key:"operation",fn:function(ref){
var row = ref.row;
return [_c('c-button',{staticClass:"button-small",attrs:{"type":"primary","size":"small"},on:{"click":function (cb){_vm.handleAddRemark(cb,row)}}},[_vm._v("备注")]),_c('c-button',{staticClass:"button-small",attrs:{"autoConfirm":true,"type":"danger","size":"small"},on:{"confirm":function (cb) {
            _vm.deleteItem(cb, row);
          }}},[_vm._v("删除")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }